import { parseDate } from "chrono-node";
import {
  add,
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  differenceInHours,
  eachHourOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isSameDay,
  parse,
  startOfDay,
  startOfMonth,
  startOfTomorrow,
  startOfWeek,
} from "date-fns";
import { DayOfWeek } from "../reclaim-api/Calendars";
import { formatFloat } from "./numbers";
import { pluralize, ucfirst } from "./strings";

// See date-fns for formatting syntax: https://date-fns.org/v2.23.0/docs/format

export const TIME_FORMAT = "HH:mm:ss"; // "03:30:00", "18:00:00"

export const TIME_DISPLAY_FORMAT = "h:mmaaa"; // "8:00 am", "1:00 pm", "12:00 pm"
export const DATE_DISPLAY_FORMAT = "EE, MMM d"; // "Tue, Aug 10"
export const DATE_YEAR_DISPLAY_FORMAT = "EE, MMM d, yyyy"; // "Tue, Aug 10, 2022"
export const DATE_TIME_DISPLAY_FORMAT = `${DATE_DISPLAY_FORMAT} 'at' ${TIME_DISPLAY_FORMAT}`; // "Tue, Aug 10th at 11:59 pm"
export const DATE_YEAR_TIME_DISPLAY_FORMAT = `${DATE_DISPLAY_FORMAT}, yyyy 'at' ${TIME_DISPLAY_FORMAT}`; // "Tue, Aug 10th, 2022 at 11:59 pm"
export const SHORT_DATE_TIME_FORMAT = `L/d ${TIME_DISPLAY_FORMAT}`; // 10/15 8:00am
export const ROUTER_DATE_FORMAT = "yyyy-MM-dd";

/**
 * Takes a date and format and returns a display date. If the returned display date does not
 * match the provided date after being parsed then add the year to the display string to
 * ensure accuracy.
 */
export const getFormattedDisplayDate = (date: Date, formatStr: string, dayMode?: boolean): string => {
  // clear out seconds and millis
  date.setSeconds(0);
  date.setMilliseconds(0);

  const formatted = format(date, formatStr);
  const formattedParsed = parseDate(formatted);

  // If the formatted string does not parse back to the original date then
  // use the precise time format with year as the formatting.
  if (formattedParsed?.getTime() !== date.getTime()) {
    return format(date, !!dayMode ? DATE_YEAR_DISPLAY_FORMAT : DATE_YEAR_TIME_DISPLAY_FORMAT);
  } else {
    return formatted;
  }
};

// 2/24 10am - 1:15pm, 2/24 10am - 2/25 1:15pm
export const getDateSpanMinimalDisplay = (start: Date, end: Date): string => {
  return `${format(start, SHORT_DATE_TIME_FORMAT).replace(":00", "")} - ${
    isSameDay(start, end)
      ? format(end, TIME_DISPLAY_FORMAT).replace(":00", "")
      : format(end, SHORT_DATE_TIME_FORMAT).replace(":00", "")
  }`;
};

export const periodStr = (start: Date, end: Date, range: "day" | "week", now: Date = new Date()) => {
  if (start < now && end > now) {
    return range === "day" ? "today" : "this week";
  }

  return range === "day" ? format(start, "EEEE") : `${format(start, "MMM d")} - ${format(end, "MMM d")}`;
};

export interface Duration {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  weeks: number;
}

export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
export const SECONDS_PER_WEEK = SECONDS_PER_DAY * 7;

export const MINUTES_PER_CHUNK = 15;
export const SECONDS_PER_CHUNK = MINUTES_PER_CHUNK * SECONDS_PER_MINUTE;

export const HOURS_PER_DAY = 24;
export const HOURS_PER_WEEK = HOURS_PER_DAY * 7;

export const durationFn = (seconds: number): Duration => {
  const dur: Duration = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    weeks: 0,
  };

  dur.weeks = Math.floor(seconds / SECONDS_PER_WEEK);
  seconds = seconds % SECONDS_PER_WEEK;
  dur.days = Math.floor(seconds / SECONDS_PER_DAY);
  seconds = seconds % SECONDS_PER_DAY;
  dur.hours = Math.floor(seconds / SECONDS_PER_HOUR);
  seconds = seconds % SECONDS_PER_HOUR;
  dur.minutes = Math.floor(seconds / SECONDS_PER_MINUTE);
  seconds = seconds % SECONDS_PER_MINUTE;
  dur.seconds = Math.round(seconds);

  return dur;
};

export const getSeconds = (duration: Partial<Duration>): number => {
  let seconds = duration.seconds || 0;
  seconds += (duration.minutes || 0) * SECONDS_PER_MINUTE;
  seconds += (duration.hours || 0) * SECONDS_PER_HOUR;
  seconds += (duration.days || 0) * SECONDS_PER_DAY;
  seconds += (duration.weeks || 0) * SECONDS_PER_WEEK;

  return seconds;
};

export const getHours = (duration: Partial<Duration>): number => getSeconds(duration) / SECONDS_PER_HOUR;

export type TimeUnit = "week" | "day" | "hr" | "min" | "sec";

const UNITS: { [U in TimeUnit]: U } = {
  week: "week",
  day: "day",
  hr: "hr",
  min: "min",
  sec: "sec",
};

/**
 * gets the largest unit that fits into a duration of time
 * @param durationOrSeconds the duration of time
 * @returns a unit of time
 */
export function getLargestContainedUnit(durationOrSeconds: number | Duration, noDaysOrWeeks = false): TimeUnit {
  let { minutes, hours, days, weeks } =
    typeof durationOrSeconds === "number" ? durationFn(durationOrSeconds) : durationOrSeconds;

  if (!noDaysOrWeeks && weeks) return UNITS.week;
  if (!noDaysOrWeeks && days) return UNITS.day;
  if (hours) return UNITS.hr;
  if (minutes) return UNITS.min;
  return UNITS.sec;
}

export const durationStrDecimal = (
  durationOrSeconds: number | Duration,
  options: { fallbackUnit?: TimeUnit; noDaysOrWeeks?: boolean }
): string => {
  const { fallbackUnit = "hours", noDaysOrWeeks = false } = options || {};
  let { seconds, minutes, hours, days, weeks } =
    typeof durationOrSeconds === "number" ? durationFn(durationOrSeconds) : durationOrSeconds;

  if (noDaysOrWeeks) {
    hours += getHours({ days, weeks });
    days = 0;
    weeks = 0;
  }

  if (weeks) {
    const num = weeks + getSeconds({ days, hours, minutes, seconds }) / SECONDS_PER_WEEK;
    return `${formatFloat(num, 2)} ${pluralize(num, UNITS.week)}`;
  }
  if (days) {
    const num = days + getSeconds({ hours, minutes, seconds }) / SECONDS_PER_DAY;
    return `${formatFloat(num, 2)} ${pluralize(num, UNITS.day)}`;
  }
  if (hours) {
    const num = hours + getSeconds({ minutes, seconds }) / SECONDS_PER_HOUR;
    return `${formatFloat(num, 2)} ${pluralize(num, UNITS.hr)}`;
  }
  if (minutes) {
    const num = minutes + getSeconds({ seconds }) / SECONDS_PER_MINUTE;
    return `${formatFloat(num, 2)} ${pluralize(num, UNITS.min)}`;
  }
  if (seconds) return `${seconds} ${pluralize(seconds, UNITS.sec)}`;

  return `0 ${UNITS[fallbackUnit]}s`;
};

/**
 * Breaks down seconds into durations
 * (eg. 3d 4h 29m)
 * @param seconds duration in seconds
 */
export const durationStr = (durationOrSeconds: number | Duration, noDaysOrWeeks = false): string => {
  let { seconds, minutes, hours, days, weeks } =
    typeof durationOrSeconds === "number" ? durationFn(durationOrSeconds) : durationOrSeconds;

  if (noDaysOrWeeks) {
    hours += HOURS_PER_DAY * days + HOURS_PER_WEEK * weeks;
    weeks = 0;
    days = 0;
  }

  let parts: string[] = [];

  // weeks
  if (weeks) parts.push(`${Math.floor(weeks)} ${pluralize(weeks, UNITS.week)}`);

  // days
  if (days) parts.push(`${Math.floor(days)} ${pluralize(days, UNITS.day)}`);

  // hours
  if (hours) parts.push(`${Math.floor(hours)} ${pluralize(hours, UNITS.hr)}`);

  // minutes
  if (minutes) parts.push(`${Math.floor(minutes)} ${UNITS.min}`);

  // seconds
  if (seconds) parts.push(`${Math.floor(seconds)} ${pluralize(seconds, UNITS.sec)}`);

  // join
  return parts.join(" ");
};

/**
 * Human readable string representation of time duration between two dates
 * (eg. "3d 4h 29m")
 * @param start start Date
 * @param end end Date
 */
export const durationBetweenStr = (start: Date, end: Date) => {
  const diff = (end.getTime() - start.getTime()) / 1000;
  const duration = durationStr(Math.abs(diff));
  return diff >= 0 ? duration : `${duration} ago`;
};

export const daysAgo = (start: Date, end: Date) => {
  const diff = (end.getTime() - start.getTime()) / 1000;
  const duration = Math.floor(diff / (60 * 60 * 24));
  return duration;
};

export const yearsAgo = (start: Date, end = new Date()): number => {
  return daysAgo(start, end) / 360;
};

/**
 * Simple human readable representation of relative distance
 * @param num count
 * @param unit defaults to "days"
 * @param plural suffix to pluralize unit
 * @returns a string such as "n units ago", "in n units"
 */
export const relativeStr = (num: number, unit: string = "day", plural: string = "s") => {
  if (num >= 0) return `in ${num} ${pluralize(num, unit, plural)}`;
  return `${Math.abs(num)} ${pluralize(num, unit, plural)} ago`;
};

export const durationStrToSecs = (str: string) => {
  // const numberRe = /\d*[.,]?\d+/;
  // const timeUnitRe = /(?:m(?:in(?:ute)?)?|h(?:(?:ou)?r)?|d(?:ay)?|w(?:(?:ee)?k)?)s?/;

  // const durationRe = /(?<value>\d*[.,]?\d+)\s?(?<unit>(?:m(?:in(?:ute)?)?|h(?:(?:ou)?r)?|d(?:ay)?|w(?:(?:ee)?k)?)s?)/g
  // const durationRe = /(?<${key}amount>\d*[.,]?\d+/
  const durationRe = /(\d*[.,]?\d+)\s*?((?:m(?:in(?:ute)?)?|h(?:(?:ou)?r)?|d(?:ay)?|w(?:(?:ee)?k)?)s?)/;

  if (!durationRe.test(str)) return;

  let mins = 0;
  let sub = str;
  let matches;

  while ((matches = durationRe.exec(sub))) {
    sub = sub.replace(matches[0], "");

    const num = 1 * (matches[1] || 0);

    switch (matches[2][0]) {
      case "s":
        mins += num;
        break;
      case "m":
        mins += num * 60;
        break;
      case "h":
        mins += num * 60 * 60;
        break;
      case "d":
        mins += num * 60 * 60 * 24;
        break;
      case "w":
        mins += num * 60 * 60 * 24 * 7;
        break;
      default:
        mins += num * 60; // default to mins
        break;
    }
  }

  return mins;
};

// FIXME (IW): Using eg `[DayOfWeek.Monday]` causes errors in tests
export const DayOfWeekIndex: Record<string, number> = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

export type TemporalPosition = "current" | "earlierToday" | "past" | "laterToday" | "future";

export const getTemporalPosition = (now: Date, start: Date, end: Date): TemporalPosition => {
  if (start < now && end > now) return "current";
  else if (end <= now) {
    if (isSameDay(end, now)) return "earlierToday";
    else return "past";
  } else if (start > now) {
    if (isSameDay(end, now)) return "laterToday";
    else return "future";
  } else return "current"; // this shouldn't ever be reached
};

export const OffsetDayOfWeekIndex = (startOfWeek: DayOfWeek = DayOfWeek.Monday) => {
  const offset = DayOfWeekIndex[startOfWeek]; // offset in 1-indexed list

  return Object.entries(DayOfWeekIndex).reduce((acc, [d, i]) => {
    acc[d] = ((7 + (i - offset)) % 7) + 1;
    return acc;
  }, {});
};

/**
 * Sort by days of the week (eg. "[Sunday, Tuesday, Friday, ...]" )
 */
export const byDayOfWeek = (a: DayOfWeek, b: DayOfWeek) => {
  const order = DayOfWeekIndex;
  const aIdx = order[a.toUpperCase()];
  const bIdx = order[b.toUpperCase()];
  if (aIdx === bIdx) return 0;
  else return aIdx > bIdx ? 1 : -1;
};

/**
 * Check if an array of DayOfWeek is sequential (eg. "[Tuesday, Wednesday, Thursday]")
 *
 * @param days
 * @returns true if sequential, false otherwise
 */
export const isDaysSequence = (days: DayOfWeek[]) => {
  return days.every(
    (day, idx, arr) => idx === 0 || DayOfWeekIndex[day.toUpperCase()] === DayOfWeekIndex[arr[idx - 1]] + 1
  );
};

/**
 * Changes an array of DayOfWeek values into a range string
 * @param days
 * @returns returns a range of days (ie: Mon - Fri) as a string
 */
export const daysStr = (days: DayOfWeek[]) =>
  isDaysSequence(days.sort(byDayOfWeek))
    ? `${ucfirst(days[0].substr(0, 3))} - ${ucfirst(days[days.length - 1].substr(0, 3))}`
    : days.map((d) => ucfirst(d.substr(0, 3))).join(", ");

const today = new Date();
today.setHours(0, 0, 0, 0);

export function addTime(
  time: Date,
  adjustments: { hour?: number; min?: number; sec?: number },
  timeFormat?: string
): Date;
export function addTime(
  time: string,
  adjustments: { hour?: number; min?: number; sec?: number },
  timeFormat?: string
): string;
export function addTime(
  time: string | Date,
  adjustments: { hour?: number; min?: number; sec?: number },
  timeFormat: string = "HH:mm:ss"
) {
  let timeAsDate = typeof time === "string" ? parse(time, timeFormat, today) : time;
  if (adjustments.hour) {
    timeAsDate = addHours(timeAsDate, adjustments.hour);
  }

  if (adjustments.min) {
    timeAsDate = addMinutes(timeAsDate, adjustments.min);
  }

  if (adjustments.sec) {
    timeAsDate = addSeconds(timeAsDate, adjustments.sec);
  }

  return typeof time === "string" ? format(timeAsDate, timeFormat) : timeAsDate;
}

export function timeGreaterThan(
  time: string | Date | undefined,
  timeThatShouldBeLessThen: string | Date | undefined,
  byAtLeast?: { hour?: number; min?: number; sec?: number },
  timeFormat = "HH:mm:ss"
) {
  if (!time) return false;
  if (!timeThatShouldBeLessThen) return true;
  const timeAsDate = typeof time === "string" ? parse(time, timeFormat, today) : time;
  const timeThatShouldBeLessThenAsDate =
    typeof timeThatShouldBeLessThen === "string"
      ? parse(timeThatShouldBeLessThen, timeFormat, today)
      : timeThatShouldBeLessThen;
  let adjustedTimeThatShouldBeLessThenAsDate = timeThatShouldBeLessThenAsDate;

  if (byAtLeast)
    adjustedTimeThatShouldBeLessThenAsDate = addTime(timeThatShouldBeLessThenAsDate, byAtLeast, timeFormat);

  return timeAsDate >= adjustedTimeThatShouldBeLessThenAsDate;
}

export function isValid(d?: Date) {
  if (!d) return false;
  return Object.prototype.toString.call(d) === "[object Date]" && d instanceof Date && !isNaN(d.getTime());
}

export function getDatesArray(start: Date, end: Date) {
  for (var arr: Date[] = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr as Date[];
}

export function localTimeToMin(localtimeString: string) {
  const parts = localtimeString.split(":"); // split it at the colons

  // Hours are worth 60 minutes.
  return +parts[0] * 60 + +parts[1];
}
export function minTolocalTime(mins: number) {
  let h = Math.floor(mins / 60);
  let m = mins % 60;

  return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:00`;
}

/**
 * Parse a formatted date used in route params (yyyy-MM-dd)
 *
 * @param str formatted date
 * @returns date if parsable, otherwise undefined
 */
export function parseRouteDate(str?: string | null) {
  if (!str) return undefined;

  switch (str.trim()) {
    case "today":
      return startOfDay(new Date());
    case "yesterday":
      return startOfDay(
        add(new Date(), {
          days: -1,
        })
      );
    case "tomorrow":
      return startOfDay(
        add(new Date(), {
          days: 1,
        })
      );
  }

  // Try newer date format first
  try {
    const parsedDate = parse(str, "yyyy-MM-dd", new Date());
    if (!isNaN(parsedDate.getTime())) return parsedDate;
  } catch (err) {
    console.warn("Failed to parse query param date", str);
  }

  // Fallback to legacy date format
  try {
    const parsedDate = parse(str, "MM-dd-yyyy", new Date());
    if (!isNaN(parsedDate.getTime())) return parsedDate;
  } catch (err) {
    console.warn("Failed to parse legacy query param date", str);
  }

  // Give up, record an error and return undefined
  console.error("Failed to parse query param date", str);
}

export function snap(date: Date, range: "chunk" | "day" | "week" | "month"): { start: Date; end: Date } {
  switch (range) {
    case "chunk":
      throw Error("Not implemented");
    case "day":
      return { start: startOfDay(date), end: endOfDay(date) };
    case "week":
      return { start: startOfDay(startOfWeek(date)), end: endOfDay(endOfWeek(date)) };
    case "month":
      return { start: startOfDay(startOfMonth(date)), end: endOfDay(endOfMonth(date)) };
  }
}

export function roundTimeToChunk(time: Date) {
  var timeToReturn = new Date(time);

  timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
  return timeToReturn;
}

export function roundTimeToNextChunk(time: Date) {
  const timeToReturn = new Date(time);

  // If 8:00:xx pick 8:00:00 to match backend.
  timeToReturn.setSeconds(0, 0);
  timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 15) * 15);

  return timeToReturn;
}

// TODO (IW): This is a dup, remove in favor of `DayOfWeekIndex`
export function dayOfWeekToNumber(day: DayOfWeek) {
  return ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"].indexOf(day);
}

export const getDayOfWeekFromDate = (date: Date): DayOfWeek => {
  return [
    DayOfWeek.Sunday,
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
    DayOfWeek.Saturday,
  ][getDay(date)];
};

export const localTimeToMinute = (localTime: string) => {
  const [hrs, mins] = localTime
    .trim()
    .split(":")
    .map((s) => Number.parseInt(s));
  return 60 * hrs + mins;
};

/**
 * Deserialize any of the various format date strings we use into a `Date`.
 * Except dates in route params, use {@link parseRouteDate} for that...
 *
 * @param dateString
 * @param throwOnError
 * @returns Date
 */
export function strToDate(dateString: string | null): Date;
export function strToDate(dateString?: null): undefined;
export function strToDate(dateString?: string | null): Date | undefined;
export function strToDate(dateString?: string | null) {
  if (!dateString) return;

  // Default JS parsing
  let parsedDate = new Date(dateString);

  if (!isNaN(parsedDate.getTime())) {
    return parsedDate;
  }

  // Time
  parsedDate = parse(dateString, "HH:mm:ss", new Date());

  if (!isNaN(parsedDate.getTime())) {
    return parsedDate;
  }

  // US date
  parsedDate = parse(dateString, "MM/dd/yyyy", new Date());

  if (!isNaN(parsedDate.getTime())) {
    return parsedDate;
  }

  // SQL date
  parsedDate = parse(dateString, "yyyy-MM-dd", new Date());

  if (!isNaN(parsedDate.getTime())) {
    return parsedDate;
  }

  console.error(`Error parsing string to a valid date`, dateString);
  return;
}

/**
 * Convenient inverse of `strToDate` so we don't have to think about serializing dates
 *
 * @param date
 * @returns ISO date string the backend expects
 */
export const dateToStr = (date?: Date | null) => {
  if (!date) return;
  return date.toISOString();
};

export function isRangeNow(start?: Date | string, end?: Date | string, now: Date = new Date()) {
  return (
    !!start &&
    !!end &&
    (typeof start === "string" ? (strToDate(start) as Date) : start) <= now &&
    (typeof end === "string" ? (strToDate(end) as Date) : end) >= now
  );
}

export function isRangeAllDay(start: Date, end: Date) {
  return differenceInHours(end, start) >= 24;
}

export function getDatesBetween(start: Date, end: Date) {
  const dates: Date[] = [];
  let currentDate = start;

  while (currentDate <= end) {
    dates.push(currentDate);
    currentDate = add(currentDate, { days: 1 });
  }

  return dates;
}

export function countPerDay(items: Array<{ start: Date; end: Date }>): Record<string, number> {
  return items.reduce((acc, item) => {
    const days = getDatesBetween(item.start, item.end);

    days.forEach((day) => {
      if (!acc[day.toDateString()]) {
        acc[day.toDateString()] = 0;
      }
      ++acc[day.toDateString()];
    });

    return acc;
  }, {});
}

/**
 * Returns true if date is within 15 minutes from now or earlier.
 */
export const isDateNowOrEarlier = (date: Date): boolean => {
  return date < addMinutes(new Date(), 15);
};

export const isDateNowOrEarlierThisWeek = (date: Date): boolean => {
  return date.getTime() < addMinutes(new Date(), 15).getTime() && date.getTime() > addDays(new Date(), -7).getTime();
};

export const isDateNowish = (date: Date): boolean => {
  const now = new Date();
  return date < addMinutes(now, 15) && date > addMinutes(now, -15);
};

export const isDateToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

export const getLatestMinuteInterval = (date: Date, interval: number = 15) => {
  let lastInterval = new Date(date.getTime());
  lastInterval.setMinutes(0, 0, 0); // Start of the hour for provided date

  const getLastInterval = () => {
    if (lastInterval.getTime() + interval * 60000 < date.getTime()) {
      lastInterval = new Date(lastInterval.getTime() + interval * 60000);
      getLastInterval();
    }
  };

  // increase by interval until closest time is reached
  getLastInterval();

  return lastInterval;
};

export const getTomorrowAtHour = (hour: number): Date => {
  const tomorrow = startOfTomorrow();
  tomorrow.setHours(hour, 0, 0, 0);
  return tomorrow;
};

export const getHoursOfDay = (anchor?: Date): Date[] => {
  let start = startOfDay(anchor || new Date());
  let end = endOfDay(anchor || start);

  return eachHourOfInterval({ start, end });
};

export default {
  isValid,
  durationStr,
  durationBetweenStr,
  byDayOfWeek,
  timeGreaterThan,
  addTime,
};
